import PropTypes from 'prop-types'
import React from 'react'
import Img from 'gatsby-image'
import { Navbar, Nav } from 'react-bootstrap'
import moment from 'moment/min/moment-with-locales'
import ReactHtmlParser from 'react-html-parser'

const Kandidat = ({ data: kandidat }) => {
  const fullName = `${kandidat.vorname} ${kandidat.nachname}`
  moment.locale('de')
  const dob = moment(kandidat.geburtsdatum).format('LL')

  const order = true

  return (
    <>
      <div className='col-md-6 col-lg-4 col-xl-12'>
        <div className='card mb-5'>
          <div className='row'>
            <div className={'col-xl-6' + (order ? ' order-xl-last' : '')}>
              <Img
                fluid={kandidat.foto.childImageSharp.fluid}
                className='card-img-top'
                alt={fullName}
                style={{
                  clipPath: 'polygon(35% 0, 100% 0, 100% 100%, 35% 100%)',
                }}
              />
              {/* {kandidat.listenplatz === 1 && (
                <a
                  href='https://www.michaelmelcher.de'
                  target='blank'
                  className='btn btn-primary btn-block mt-3 d-none d-xl-block'>
                  Zur Webseite von Michael Melcher
                </a>
              )} */}
            </div>
            <div className='col-xl-6'>
              <div className='card-body px-0 pt-xl-0'>
                <h3 className='card-title'>
                  <span style={{ fontWeight: 'bold', marginLeft: '0em' }}>
                    {fullName}
                  </span>
                </h3>
                <div className='text-primary mb-3'>
                  {kandidat.listeKreistag}
                </div>

                <q className='card-text'>{kandidat.grund}</q>
              </div>
              {/* {kandidat.listenplatz === 1 && (
                <a
                  href='https://www.michaelmelcher.de'
                  target='blank'
                  className='btn btn-primary btn-block mt-n2 mb-3 d-block d-xl-none'>
                  Zur Webseite von Michael Melcher
                </a>
              )} */}
              <div className='mb-2'>
                <div className='small'>
                  <b>Geburtsdatum</b>
                </div>
                <div>{dob}</div>
              </div>
              <div className='mb-2'>
                <div className='small'>
                  <b>Beruf</b>
                </div>
                <div>{kandidat.beruf}</div>
              </div>
              {kandidat.familie && (
                <div className='mb-2'>
                  <div className='small'>
                    <b>Familie</b>
                  </div>
                  <div>{kandidat.familie}</div>
                </div>
              )}
              {/* {kandidat.mandate && (
                <div className='mb-2'>
                  <div className='small'>
                    <b>Mandate und Ehrenamt</b>
                  </div>
                  <div>{kandidat.mandate}</div>
                </div>
              )} */}
              {kandidat.hobbys && (
                <div className='mb-2'>
                  <div className='small'>
                    <b>Hobbys</b>
                  </div>
                  <div>{kandidat.hobbys}</div>
                </div>
              )}
              {kandidat.ziel && (
                <div className='mb-2'>
                  <div className='small'>
                    <b>Ziele</b>
                  </div>
                  <div>{ReactHtmlParser(kandidat.ziel)}</div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Kandidat
