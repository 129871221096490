import React from 'react'
import { Link, graphql, useStaticQuery } from 'gatsby'
import Img from 'gatsby-image'
import BGImg from 'gatsby-background-image'

import '../index.css'

import Layout from '../../components/layout'
import SEO from '../../components/seo'
import Kandidat from '../../components/kandidat'

const Wahl2020Page = () => {
  const data = useStaticQuery(graphql`
    query {
      wahl: file(relativePath: { eq: "wahl2020/kandidatenreihe.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 2000, quality: 80) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      kandidaten: allKandidatenXlsxBlatt1 {
        edges {
          node {
            listenplatz
            nachname
            vorname
            geburtsdatum
            beruf
            familie
            mandate
            hobbys
            grund
            ziel
            foto {
              childImageSharp {
                fluid {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  `)

  const kandidaten = data.kandidaten.edges

  return (
    <Layout>
      <SEO title='CSU Neutraubling' />
      <BGImg
        className='jumbotron jumbotron-fluid mobile-header-bg'
        style={{ backgroundPosition: 'top center' }}
        fluid={data.wahl.childImageSharp.fluid}>
        <div className='container'>
          <h1
            className='display-4 text-light mobile-header'
            style={{ textShadow: '0 0 10px black', fontWeight: 'normal' }}>
            Kommunalwahlen 2020
          </h1>
        </div>
      </BGImg>
      <div className='container my-5'>
        <p>
          Mit unseren 24 Kandidatinnen und Kandidaten, die mir ihrem Alter,
          ihren Lebenssituationen und Erfahrungen die Vielseitigkeit unserer
          Stadt repräsentieren, haben wir eine starke Liste zusammengestellt,
          die mit großer Überzeugung für unsere Stadt Neutraubling eintritt.
          Zusammen mit Michael Melcher als Bürgermeisterkandidat wollen wir,
          dass Neutraubling weiter mit Herz und Verstand regiert wird.
        </p>
        <div className='row mt-5'>
          {kandidaten.map(({ node: kandidat }) => {
            return <Kandidat data={kandidat} key={kandidat.listenplatz} />
          })}
        </div>
      </div>
    </Layout>
  )
}

export default Wahl2020Page
